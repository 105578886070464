.comment-textarea {
  background-color: $gray-200;
  .textinput__highlighter {
    box-sizing: border-box;
    overflow: hidden;
    min-height: 40px;
    max-height: 600px;
  }

  .textinput__input {
    overflow: auto !important;
    min-height: 40px;
    max-height: 600px;
    border: none;
    &:focus {
      border: none;
      outline: none;
    }
  }

  & .comment-buttons {
    & .chat-button {
      cursor: pointer;
      height: 30px;
      width: 30px;
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
      & .icon {
        background-color: #AFAFAF;
      }
    }
  }

  & .comment-attachments {
    display: flex;
    // padding-left: 10px;
    margin-bottom: 10px;
    & .comment-attachment {
      padding-left: 10px;
      padding-bottom: 5px;
      color: $primary;
      cursor: pointer;
      &:hover {
        font-weight: 700;
      }
    }
    & .file {
      border: 2px solid $gray-200;
      border-radius: 5px;
      max-width: 70px;
      & .thumbnail {
        min-width: 60px;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
          max-width: 60px;
          max-height: 60px;
        }
      }
      & .filename {
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.comments {
  background-color: $gray-200;
  max-height: 600px;
  overflow-y: auto;
  & .comment-padding {
    padding-left: 30px;
    background-color: $gray-200;
  }

  & .comment {
    background-color: $white;
    border-top: 1px solid $gray-200;
    font-weight: 300;
    &.resolved {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    & .resolved {
      display: inline-flex;
      align-items: baseline;
      cursor: pointer !important;

      &::before {
        content: ' ';
        margin-right: 5px;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        background-color: $success;
      }
      & .comment-reopen {
        display: none;
      }
      & .comment-status {
        display: block;
      }
      &:not(.no-hover):hover {
        &::before {
          content: ' ';
          margin-right: 5px;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          background-color: $info;
        }
        & .comment-reopen {
          display: block;
        }
        & .comment-status {
          display: none;
        }
      }
    }
    & .comment-header {
      padding-left: 10px;
      padding-top: 20px;
      font-weight: 300;
      & .comment-date {
        color: $gray-500;
      }
      & .comment-author {
        font-weight: 400;
        margin-left: 10px;
      }
    }

    & .comment-text {
      padding-left: 10px;
      margin-top: 20px;
      & .emoji-mart-emoji {
        width: 16px;
        height: 16px;
        & span {
          position: absolute;
          top: 3px;
        }
      }
      & pre {
        font-size: 100%;
        word-break: break-all;
        white-space: pre-wrap;
      }
    }

    & .comment-footer {
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 80%;
      font-weight: 400;
      & .comment-reply {
        color: $success;
        text-transform: uppercase;
        cursor: pointer;
      }
      & .comment-resolve {
        display: inline-flex;
        align-items: baseline;
        &::before {
          content: ' ';
          margin-right: 5px;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          border: 1px solid $success;
        }
      }

      & .comment-btn:first-of-type {
        margin-left: 0;
      }

      & .comment-btn {
        margin-left: 20px;
        color: $success;
        text-transform: uppercase;
        cursor: pointer;
        &.resolved {
          cursor: default;
        }
      }
    }
  }
}