.file-preview {
  min-height: 100px;
  min-width: 400px;
  & img {
    max-height: 600px;
    width: 100%;
    object-fit: scale-down;
  }
}

.chess {
  background-image: linear-gradient(45deg, #e7e7e7 25%, transparent 25%), linear-gradient(-45deg, #e7e7e7 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e7e7e7 75%), linear-gradient(-45deg, transparent 75%, #e7e7e7 75%);
  background-size: 6px 6px;
  background-position: 0 0, 0 3px, 3px -3px, -3px 0px;
}

.chess-blue {
  background-image: linear-gradient(45deg, #E5E8FC 25%, transparent 25%), linear-gradient(-45deg, #E5E8FC 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #E5E8FC 75%), linear-gradient(-45deg, transparent 75%, #E5E8FC 75%);
  background-size: 10px 10px;
  background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}


