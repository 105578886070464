@mixin loading($bg) {
	
	&:after {
        position: absolute;
        background-color: $bg;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        content: "";
        height: 100%;
        animation: loading-pulse 1s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:before {
        position: absolute;
        opacity: 0.5;
        background-color: $bg;
        top: 0;
        left: 0;
        width: 8%;
        max-width: 50px;
        z-index: 1000;
        content: "";
        height: 100%;
        -webkit-filter: blur(5px);
        filter: blur(5px);
        animation: loading-move 1.2s infinite linear;
    }
}
.loading {
  position: relative;
  overflow: hidden !important;

  @include loading($white);

  &.btn{
      border: 1px solid rgba(255, 0, 0, .0);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
  }

  &.disabled{
      opacity: inherit !important;
  }
}


@keyframes loaded-pop {
  from {
      transform: scale(0.98);
      opacity: 0.1;
  }
  to {
      transform: scale(1);
      opacity: 1.0;
  }
}

@keyframes loading-pulse {
  0% {
      opacity: 0.2;
  }
  50% {
      opacity: 0.6;
  }
  100% {
      opacity: 0.2;
  }
}

@keyframes loading-move {
  0% {
      left: 0;
  }

  100% {
      left: 100%;
  }
}