.icon-btn {
  display: flex;
  align-items: center;
  & .text {
    padding-top: 3px;
  }
}

.page-item.active .page-link.active {
  background-color: #0f54fb;
  border-color: #0f54fb;
}