.color-title {
  color: $gray-600;
  padding: 14px 0 9px 0;
  color: #AFAFAF;
  // font-weight: 700;
}
.colors {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;

  & .color {
    // border: 1px solid $gray-200;
    position: relative;
    margin-top: 5px;
    &:not(:first-of-type) {
      margin-left: 1px;
    }
    & .color-preview {
      width: 80px;
      height: 80px;
      border: 1px solid #F5F5F5;
    }
    & .color-text {
      text-align: center;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      letter-spacing: 2.4px;
      margin-top: 9px;
      text-transform: uppercase;
    }
    
    & .color-delete {
      display: none;
      position: absolute;
      z-index: 1000;
      top: 2px;
      right: 3px;
    }
    &:hover .color-delete {
      display: block;
      cursor: pointer;
    }
  }
}