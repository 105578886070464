.project-board {
  & .project-board-item {
    width: 320px;
    margin-right: 20px;
    border: 1px dashed $gray-200;
    padding: 5px;
    height: 500px;
    overflow-y: auto;

    & .project-item {
      background-color: $white;
      border-radius: 1px;
      border: 1px solid $gray-200;
      padding: 1px;
      margin-bottom: 5px;

      & .project-avatar-container {
        position: relative;
        & .project-avatar-preloader {
          margin-top: calc(-50% - 8px);
          margin-left: calc(50% - 8px);
          position: absolute;
          &::before {
            width: 64px;
            height: 64px;
            background-color: black;
            opacity: 0.5;
          }
        }
      }
      &.create-project {
        cursor: pointer;
        background: repeating-linear-gradient(
          45deg,
          #ffffff,
          #ffffff 4px,
          #f5f5f5 0px,
          #f5f5f5 20px
        );
      }

      &.toDo {
        border-right: 3px solid $gray-200;
      }

      &.inProgress {
        border-right: 3px solid $primary;
      }

      &.done {
        border-right: 3px solid $success;
      }

      & .project-thumbnail {
        min-width: 64px;
        min-height: 64px;
        & img {
          max-width: 64px;
          min-height: 64px;
        }
      }

      & .project-thumbnail-placeholder {
        min-width: 64px;
        min-height: 64px;
        background-color: $gray-200;
        display: flex;
        justify-content: center;
        align-items: center;
        font-variant-caps: all-petite-caps;
        color: $gray-400;
      }

      & .project-body {
        & .single-input-from .form-group {
          margin: 0;
          padding: 3px 5px;
          & .btn {
            font-size: 70%;
          }
        }
        & .project-name .project-edit-btn {
          display: none;
        }
        & .project-name:hover {
          & .project-edit-btn {
            display: block;
            cursor: pointer;
          }
        }
        & .project-progressbar {
          display: none;
          & .progress {
            height: 2px;
            margin-bottom: -1px;
          }
        }
      }
    }
  }
}

.project-body {
  & .single-input-from .form-group {
    margin: 0;
    padding: 3px 5px;
    & .btn {
      font-size: 70%;
    }
  }
}
