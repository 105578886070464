.sidebar {
  background-color: #0000B4;
    flex: 1 0 200px;
    min-height: 100vh;
    z-index: 1000;
  & .nav-link {
    color: $white;
  }

  & .separator {
    border-bottom: 1px solid $white;
  }
  & .active {
    color: #FDBB42;
  }
}

.content {
  flex: 0 1 100%;
  min-height: 100vh;
  & .topbar {
    padding: 15px 5px;
    box-shadow: 0px 3px 3px 0px rgba(204, 204, 204, 0.18);
  }
}