.single-input-form {
  border-bottom: 1px solid $gray-300;
}

.brand-book-form {
  height: 100px !important;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}

control[readonly] {
  background-color: #f5f5f5;
  opacity: 1;
}

.team-info {
  // padding: 5px;
  min-height: 100px !important;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
  max-height: 300px;
  overflow-y: auto;
}
