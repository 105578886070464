@import '_overwrite';
@import '~bootstrap/scss/bootstrap';

@import './layout.scss';
@import './preloaders.scss';
@import './navs.scss';
@import './project.scss';
@import './team.scss';
@import './dropzone.scss';
@import './imageUploader.scss';
@import './sideModal.scss';
@import './comments.scss';
@import './icon.scss';
@import './brand.scss';
@import './input.scss';
@import './alerts.scss';
@import './cutomToggle.scss';
@import './avatars.scss';
@import './fileAssetView.scss';
@import './buttons.scss';
@import './filePreview.scss';

.btn:focus,
.btn:active,
a:focus,
a:active,
.custom-select {
  outline: none !important;
  box-shadow: none !important;
}

* {
  -webkit-font-smoothing: antialiased;
  outline: none !important;
}

.tableview {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  background-color: $white;
  & .table thead th {
    border-bottom: none;

    &.sorting {
      cursor: pointer;
    }
  }
  & .table td {
    vertical-align: middle;
  }

  & .tableview-head {
    padding: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    & .tableview-head-label {
      font-size: 1.3125rem;
      font-weight: 500;
      line-height: 1.16667em;
    }
  }

  & .tableview-content {
    padding: 0 24px;
    overflow-x: hidden;
  }

  & .tableview-foot {
    // height: 56px;
    display: flex;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.43) !important;
  z-index: 10000;
}

.bg-secondary-tr {
  background-color: #e5edff;
}
