.side-modal {
  width: auto;
  height: 100%;
  right: 0;
  left: auto;
  & .modal-dialog {
    margin: 0;
    width: 800px;
    max-width: inherit;
    height: 100%;
    & .modal-content {
      min-height: 100%;
      & .modal-header {
        padding: 0 10px;
        background-color: $black;
        & .close {
          opacity: 1;
          color: $white;
          text-shadow: none;
          margin: -1rem -1rem -1rem -1rem;
        }
      }
    }
  }
}

.side-modal-body {
  overflow-y: auto !important;
}