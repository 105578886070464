.image-uploader-container {
  position: relative;
  & .image-uploader {
    display: none;
    background-color: $gray-300;
    position: absolute;
    right: 0;
    bottom: 0;
    & div, & a {
      color: $black;
      padding: 5px 10px;
      cursor: pointer;
      &.disabled {
        opacity: 0.2;
        cursor: default;
      }
      &:hover:not(.disabled) {
        background-color: $primary;
        color: $white;
      }
    }
  }
  &:hover {
    & .image-uploader {
      display: flex;
    }
  }

  & .image-uploader.loading {
    display: flex;
  }
  
}