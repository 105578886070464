.dropzone {
  border: 1px solid $primary;
  background-color: #EAF0FF;
  min-height: 100px;
  & .placeholder {
    color: $primary;
  }
  & .file {
    border: 2px solid $gray-200;
    border-radius: 5px;
    & .thumbnail {
      min-width: 70px;
      min-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        max-width: 70px;
        max-height: 70px;
      }
    }
    & .filename {
      max-width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}