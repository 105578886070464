.brand-avatar {
  min-height: 150px;
  min-width: 150px;
  max-width: 320px;
  display: flex;
  // align-items: center;
  justify-content: start;
  & img {
    max-width: 100%;
    max-height: 320px;
  }
}

.team-avatar {
  width: 100%;
  display: flex;
  justify-content: start;
  & img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
}

.project-avatar {
  // min-height: 64px;
  // width: 64;
  // max-width: 600px;
  // display: flex;
  // justify-content: start;
  & img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center;
  }
}

.avatar {
  & img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  &.small img {
    object-fit: scale-down !important;
  }
  &.rounderd img {
    border-radius: 50%;
  }
}

.avatar-head {
  & img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
  }
}

.profile-img {
  & img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}

.user-avatar {
  min-height: 150px;
  min-width: 150px;
  max-width: 320px;
  display: flex;
  justify-content: start;
  & img {
    max-width: 100%;
    max-height: 320px;
  }
}
.user-accesses {
  display: flex;
  align-items: center;

  & span.user-empty-thumb:first-of-type {
    margin-left: 10px !important;
  }

  & .user-empty-thumb {
    margin-left: -10px;
    border-radius: 50%;
    padding: 7px;
    line-height: 1;
    font-weight: 700;
    max-width: 25px;
    max-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}
