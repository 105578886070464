.icon {
  width: 16px;
  height: 16px;
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center !important;
  -webkit-mask-position: center !important;
  mask-size: contain !important;
  -webkit-mask-size: contain !important;

  background-color: $gray-600;

  &.x10 {
    width: 10px;
    height: 10px;
  }

  &.x20 {
    width: 20px;
    height: 20px;
  }

  &.x24 {
    width: 24px;
    height: 24px;
  }

  &.x32 {
    width: 32px;
    height: 32px;
  }

  &.x40 {
    width: 32px;
    height: 32px;
  }

  &.x64 {
    width: 64px;
    height: 64px;
  }

  &.icon-smile {
    mask: url('../assets/icons/smile.svg');
    -webkit-mask: url('../assets/icons/smile.svg');
  }
  &.icon-attachment {
    mask: url('../assets/icons/attachment.svg');
    -webkit-mask: url('../assets/icons/attachment.svg');
  }
  &.icon-remove {
    mask: url('../assets/icons/remove.svg');
    -webkit-mask: url('../assets/icons/remove.svg');
  }
  &.icon-edit {
    mask: url('../assets/icons/edit.svg');
    -webkit-mask: url('../assets/icons/edit.svg');
  }

  &.icon-remove-o {
    background-color: transparent;
    background: url('../assets/icons/remove.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-plus-blue {
    background-color: transparent;
    background: url('../assets/icons/circle-with-plus.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-google {
    background-color: transparent;
    background: url('../assets/icons/google.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-FB {
    background-color: transparent;
    background: url('../assets/icons/FB.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-circle-with-cross {
    background-color: transparent;
    background: url('../assets/icons/circle-with-cross.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-folder-black {
    background-color: transparent;
    background: url('../assets/icons/folder-black.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-folder-blue {
    background-color: transparent;
    background: url('../assets/icons/folder-blue.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-creative-set {
    background-color: transparent;
    background: url('../assets/icons/creative-set.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-download-blue {
    background-color: transparent;
    background: url('../assets/icons/download-blue.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
