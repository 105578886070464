.file-asset {
  & .file-asset-tabs {
    // margin-bottom: 13px;
    & .tab-item {
      color: $gray-500;
      margin-right: 20px;
      cursor: pointer;
    }
    &.filtes {
      margin-top: 25px;
    }
  }

  .file-asset-breadcrumb {
    margin-bottom: 10px;
    display: flex;
    & .breadcrumb-i {
      &:last-of-type {
        &::before {
          padding: 0 10px;
          content: '\003e';
          color: #000000;
        }
      }
      // &:first-of-type {
      //   padding-right: 5px;
      //   &::before {
      //     content: '';
      //     padding: 0;
      //   }
      // }
      &::before {
        padding: 0 10px;
        content: '\003e';
      }
      cursor: pointer;
      &.active {
        color: $primary;
        cursor: default;
      }
    }
  }

  & .assets {
    max-height: 600px;
    overflow-y: auto;
    // display: grid;
    // // grid-template-columns: repeat(auto-fill, 80px);
    // column-gap: 20px;
    // row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    & > div {
      margin: 10px;
    }
    & .thumb {
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1px solid #e7e7e7;
      border-radius: 2px;
      position: relative;
      & .icon {
        width: 100%;
        height: 100%;
      }
      &.selected {
        border: 2px solid #feca57;
        background-color: rgba(#feca57, 0.1);
        & img {
          padding: 3px;
        }
      }
      & .version {
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 0.4rem !important;
      }
      & img {
        border-radius: 2px;
        max-width: 80px;
        max-height: 80px;
        padding: 1px;
      }
    }

    & .folder {
      border: none;
      background: url('../assets/icons/folder.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      &.selected {
        background: url('../assets/icons/folder-selected.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        background-color: transparent;
      }
    }

    & .name {
      margin-top: 12px;
      font-size: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      // white-space: nowrap;
      -webkit-line-clamp: 2;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
